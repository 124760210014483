import React, { useState, useEffect } from 'react'
import ResponseMsg from '../general/ResponseMsg';

const ManageDues = () => {
  const [response, setResponse] = useState();
  const [payData, setPayData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [formData, setFormData] = useState({
    amount_received: '',
    payment_mode: '',
  })

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`https://api.kcmpharmacy.com/dist/get-payment-history`);
        if (response.ok) {
          const data = await response.json();
          const sortedProducts = data.sort((a, b) => b.id - a.id);
          const filteredPayments = sortedProducts.filter(payment => payment.total_amount != payment.amount_received);
          setPayData(filteredPayments);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchPayments();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const showEditDialog = (paymentData) => {
    setSelectedItem(paymentData);
    setShowEdit(true);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };


  const handleUpdateAmount = async (e) => {
    e.preventDefault();

    const newAmountReceived = parseFloat(selectedItem.amount_received) + parseFloat(formData.amount_received);

    try {
      const response = await fetch(`https://api.kcmpharmacy.com/dist/update-payment/${selectedItem.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount_received: newAmountReceived.toFixed(2),
          payment_mode: formData.payment_mode,
          due_amount: selectedItem.due_amount - formData.amount_received,
        }),
      });

      if (response.ok) {
        setResponse('Due Amount updated Successfully.');
        setPayData(prevPayData => 
          prevPayData.map(payData =>
            payData.id === selectedItem.id
              ? { ...payData, amount_received: newAmountReceived, due_amount: selectedItem.due_amount - formData.amount_received, payment_mode: formData.payment_mode }
              : payData
          )
        );
        setTimeout(() => {
          setResponse('');
        }, 2000);
        setFormData({
          amount_received: '',
          payment_mode: '',
        })
        setShowEdit(false);
      } else {
        setResponse('Unable to update Due Amount.')
        setTimeout(() => {
          setResponse('');
        }, 2000);
      }
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  return (
    <>
      <section className='px-3 py-3'>
        <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
          <p className='profile-heading'>Manage Dues</p>
          <hr className='profile-hr my-0' />

          <div className='table-responsive'>
            <table className='w-100'>

              <tr className='inventory-head'>
                <th>Invoice No</th>
                <th>Order Date</th>
                <th>Party Name</th>
                <th>Order Id</th>
                <th>Total Amount</th>
                <th>Amount Paid (₹)</th>
                <th>Due Amount (₹)</th>
                <th>Payment Mode</th>
                <th>Activity</th>
              </tr>


              {payData.length > 0 ? (
                payData.map((paymentData) => (
                  <tr className='inventory-data'>
                    <td>{paymentData.id}</td>
                    <td>{formatDate(paymentData.order_date)}</td>
                    <td>{paymentData.customer_name}</td>
                    <td>{paymentData.order_id}</td>
                    <td>{paymentData.total_amount}</td>
                    <td className='text-success'>₹ {paymentData.amount_received}</td>
                    <td className='text-danger'>₹ {paymentData.due_amount}</td>
                    <td>{paymentData.payment_mode}</td>
                    <td className='text-center' onClick={() => showEditDialog(paymentData)}><span className='cursor-pointer'><i class="fa-solid fs-5 fa-pen-to-square"></i></span></td>
                  </tr>
                ))
              ) : (
                <p>No Products added yet.</p>
              )}

            </table>
          </div>


        </div>
      </section>


      {showEdit && (
        <>
          <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowEdit(false)}>
            <div className='detail-dialog-inner col-xl-4 col-lg-5 col-md-8 col-sm-10 col-12 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>
              <p className='profile-subheading my-3'>Due Amount: ₹ {selectedItem.due_amount}</p>

              <form className="row g-3 bg-white rounded-4 mb-4" onSubmit={handleUpdateAmount}>
                {/* Product Name */}

                <div className="col-12">
                  <label htmlFor="amount_received" className="profile-label text-muted">Amount</label>
                  <input
                    type="text"
                    className="profile-input"
                    id="amount_received"
                    value={formData.amount_received}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-12">
                  <label className="profile-label text-muted" htmlFor="payment_mode">Payment Mode</label>
                  <select
                    className="profile-input"
                    id="payment_mode"
                    value={formData.payment_mode}
                    onChange={handleChange}
                    required
                  >
                    <option value="Cash" selected>Cash</option>
                    <option value="UPI">UPI</option>
                    <option value="Debit Card">Debit Card</option>
                    <option value="Credit Card">Credit Card</option>
                  </select>
                </div>

                <div className='col-12'>
                  <button className='login-btn bg-danger' onClick={() => setShowEdit(false)}>Close</button>&nbsp;&nbsp;
                  <button className='login-btn'>Update</button>
                </div>

              </form>

            </div>
          </section>
        </>
      )}

      {response && <ResponseMsg message={response} />}

    </>
  )
}

export default ManageDues

import React, { useState, useRef } from 'react'
import Camera from '../assets/camera-img.png';
import ResponseMsg from '../general/ResponseMsg';

const AddOffer = () => {
    const [response, setResponse] = useState();
    const [formData, setFormData] = useState({
        name: '',
        tagline: '',
        description: '',
        offerImage: null
    });

    const [imagePreview, setImagePreview] = useState(null);


    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [id]: files[0]
            });
            // Update image preview
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };


    const handleOfferSubmit = async (e) => {
        e.preventDefault();

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSubmit.append(key, formData[key]);
        });


        try {
            const response = await fetch('https://api.kcmpharmacy.com/dist/add-offer', {
                method: 'POST',
                body: formDataToSubmit
            });

            if (response.ok) {
                setResponse('Offer added successfully!');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
            } else {
                setResponse('Failed to add offer.');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
            }
        } catch (error) {
            console.error('Error adding offer:', error);
        }
    };


    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3'>
                    <p className='profile-heading'>Add Offers</p>
                    <hr className='profile-hr' />

                    <form className="row g-3 mt-4" onSubmit={handleOfferSubmit}>

                        <input
                            type="file"
                            className="profile-input bg-white"
                            id="offerImage"
                            ref={fileInputRef}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <img
                            src={imagePreview || Camera}
                            className='camera-img w-auto cursor-pointer'
                            alt="Logo"
                            onClick={handleImageClick}
                        />

                        <div className="col-12">
                            <label htmlFor="name" className="profile-label">Offer Name</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="tagline" className="profile-label">Offer Tagline</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="tagline"
                                value={formData.tagline}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="description" className="profile-label">Offer Description</label>
                            <textarea
                                type="text"
                                className="profile-input"
                                style={{ height: '10em' }}
                                id="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            >
                            </textarea>
                        </div>

                        {/* Submit Button */}
                        <div className="col-12 text-end">
                            <button type="submit" className="login-btn">Submit Details</button>
                        </div>

                    </form>

                </div>
            </section>

            {response && <ResponseMsg message={response} />}

        </>
    )
}

export default AddOffer

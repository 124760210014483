import React, { useState, useEffect } from 'react'
import extraImg from '../assets/empty-cart.gif';
import ResponseMsg from '../general/ResponseMsg';


const ManageOrders = () => {
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOrder, setSelectedOrder] = useState();
    const [showUpdate, setShowUpdate] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [response, setResponse] = useState('');


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(`https://api.kcmpharmacy.com/dist/get-orders`);
                if (response.ok) {
                    const data = await response.json();
                    const sortedProducts = data.sort((a, b) => b.id - a.id);
                    setOrders(sortedProducts);
                } else {
                    console.error('Failed to fetch products');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchOrders();
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    };

    const filteredOrders = orders.filter(order => {
        const orderItems = JSON.parse(order.order_items);
        const isDateMatch = formatDate(order.order_date).toLowerCase().includes(searchQuery.toLowerCase());
        const isUserMatch = order.user_name.toLowerCase().includes(searchQuery.toLowerCase());
        const isIdMatch = order.id && order.id.toString().toLowerCase().includes(searchQuery.toLowerCase());
        const isProductNameMatch = orderItems.some(orderItem =>
            orderItem.product_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        return isDateMatch || isProductNameMatch || isUserMatch || isIdMatch;
    });

    const handleUpdateDialog = (order) => {
        setSelectedOrder(order);
        setShowUpdate(true);
    }

    const handleUpdateStatus = async (orderId, status) => {
        try {
            const response = await fetch(`https://api.kcmpharmacy.com/dist/update-order-status/${orderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: status,
                    payment_mode: selectedOrder.payment_mode,
                    due_amount: selectedOrder.total_amount,
                }),
            });

            if (response.ok) {
                const updatedOrder = await response.json();
                setOrders(prevOrders =>
                    prevOrders.map(order =>
                        order.id === orderId ? { ...order, order_status: status } : order
                    )
                );
                setShowUpdate(false);
                setResponse('Order status updated successfully:');
                setTimeout(() => {
                    setResponse('');
                }, 2000)
            } else {
                setResponse('Failed to update order status');
                setTimeout(() => {
                    setResponse('');
                }, 2000)
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>Manage Orders</p>

                    <div className="text-start mb-4">
                        <input
                            type="text"
                            className="profile-input rounded border-0 bg-light"
                            placeholder="🎯 Search order here..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>


                    {filteredOrders.length > 0 ? (
                        filteredOrders.map((order) => {
                            const orderItems = JSON.parse(order.order_items);
                            return (
                                <div className='my-3' key={order.id}>
                                    <div className='order-area'>
                                        <p className='fw-semibold'>Order placed on {formatDate(order.order_date)}</p>
                                        <p className='text-muted fw-semibold para-text'>Party Name: {order.user_name}<br />
                                            Mobile No: {order.user_mobile}<br />Address: {order.user_address} {order.user_city} {order.user_state}`
                                        </p>
                                        {orderItems.length > 0 ? (
                                            orderItems.map((item, index) => (
                                                <>
                                                    <div key={index} className='row mt-3'>
                                                        <img
                                                            src={`https://api.kcmpharmacy.com/uploads/${item.image}`}
                                                            className='col-xl-1 col-md-2 col-sm-3 col-4'
                                                            alt='Order'
                                                        />
                                                        <div className='col-md-6 col-sm-5 col-8'><p className='mb-0 product-name'>{item.product_name}</p>
                                                            <span className='text-muted para-text'>{item.quantity} Item<br />₹ {item.product_sp * item.quantity}</span></div>

                                                        <div className='col-md-4 col-sm-4 col-12 my-2'>
                                                            <p className='mb-0 product-name'>Payment Details</p>
                                                            {order.payment_mode == 'Cash' ? <span className='text-muted para-text'>Payment Mode: {order.payment_mode}</span> : 
                                                            <span className='text-muted para-text'>Payment Mode: {order.payment_mode}<br />Payment Id: {order.payment_id}<br />
                                                            Payment Status: {order.payment_status}<br /></span>}
                                                            
                                                        </div>

                                                    </div>
                                                </>
                                            ))
                                        ) : (
                                            <p>No items in this order</p>
                                        )}
                                        <p className='text-muted fw-semibold'>Order Total ₹ {order.total_amount}<br />This Order has been {order.order_status}</p>
                                        {order.order_status != 'Delivered' ?
                                            <button className='add-btn' onClick={() => handleUpdateDialog(order)}>Update Order Status</button> : ''}
                                        <hr />
                                    </div>
                                </div>
                            );

                        })
                    ) : (
                        <div className='text-center'>
                            <img src={extraImg} className='img-fluid col-lg-4 col-md-6 col-sm-6 col-10' alt='Staff' />
                            <p>No Orders Placed yet</p>
                        </div>
                    )}


                </div>
            </section>

            {showUpdate && (
                <>
                    <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowUpdate(false)}>
                        <div className='detail-dialog-inner col-xl-4 col-lg-5 col-md-8 col-sm-10 col-12 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>
                            <p className='profile-subheading my-3'>Update Order Status</p>

                            <select
                                id="order-status"
                                className="profile-input mb-4"
                                value={newStatus}
                                onChange={(e) => setNewStatus(e.target.value)}>
                                <option value="">Select status</option>
                                <option value="Confirmed">Confirmed</option>
                                <option value="Dispatched">Dispatched</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Delivered">Delivered</option>
                            </select>

                            <button className='login-btn' onClick={() => handleUpdateStatus(selectedOrder.id, newStatus)}>Yes</button>&nbsp;&nbsp;
                            <button className='login-btn bg-danger' onClick={() => setShowUpdate(false)}>No</button>
                        </div>
                    </section>
                </>
            )}

            {response && <ResponseMsg message={response} />}

        </>
    )
}

export default ManageOrders

import React, { useState } from 'react'
import '../css/components/Login.css';
import Logo from '../assets/logo.png';
import 'animate.css';
import ResponseMsg from '../general/ResponseMsg';
import { useNavigate } from 'react-router-dom';
// import LoginRight from '../assets/login-right.png';

const Login = () => {
  const [response, setResponse] = useState(false);
  const [formData, setFormData] = useState({
    mobile: '',
    password: '',
  });
  const navigate = useNavigate();


  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle sign-in
  const handleSignin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.kcmpharmacy.com/dist/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log(result);

      if (response.ok) {
        if(result[0].mobile == '9876543210'){
          localStorage.setItem('userData', JSON.stringify(result));
          localStorage.setItem('isAdmin', 'true');
          setResponse('Login successful');
          setTimeout(() => {
            setResponse('');
            navigate('/admin-dashboard');
            window.location.reload();
          }, 2000);
        }
          else {
            localStorage.setItem('userData', JSON.stringify(result));
            localStorage.setItem('isUser', 'true');  
            setResponse('Login successful');
            setTimeout(() => {
              setResponse('');
              navigate('/dashboard');
              window.location.reload();
            }, 2000);  
          }
      } else if (response.status == 200) {
        setResponse(result.message || 'Login failed. Please try again.');
        setTimeout(() => {
          setResponse('');
        }, 2000);
      }
    } catch (error) {
      console.log('error', error);
    }
  };


  return (
    <>
      <section className='login-bg'>

        <div className='row'>

          <div className='col-lg-6 col-12 align-content-center px-lg-5 px-4 my-4'>
            <div className='login-left col-lg-9 col-md-9 col-sm-9 animate__animated animate__fadeInLeft'>
              <img src={Logo} className='login-logo' alt='Logo' />

              <form class="row g-4 mt-3" onSubmit={handleSignin}>
                <div class="col-12">
                  <input type="tel" class="login-input" id="inputMobile4" maxLength='10' name='mobile' value={formData.mobile} onChange={handleChange} placeholder='Mobile Number' />
                </div>
                <div class="col-12">
                  <input type="password" class="login-input" id="inputPassword4" name='password' value={formData.password} onChange={handleChange} placeholder='Enter Password' />
                </div>
                <div class="col-12 mt-4 text-end">
                  <button type="submit" class="login-btn">Sign In</button>
                </div>
              </form>

            </div>
          </div>


          <div className='col-lg-6 col-12 login-right animate__animated animate__fadeInTopRight order-lg-last order-first'>
            <div className='login-right-inner'>
              <p className='login-heading'>KCM Distributor<br />Software</p>
              {/* <img src={LoginRight} className='img-fluid' /> */}
            </div>
          </div>


        </div>
        {/* row endss */}

      </section>

      {response && <ResponseMsg message={response} />}

    </>
  )
}

export default Login

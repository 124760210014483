import React, { useState, useEffect, useRef } from 'react'
import '../css/Admin/Inventory.css';
import ResponseMsg from '../general/ResponseMsg';
import Camera from '../assets/camera-img.png';


const Inventory = () => {
    const [response, setResponse] = useState();
    const [item, setItem] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [formData, setFormData] = useState({
        productName: '',
        productUnit: '',
        stock: '',
        barcode: '',
        hsnCode: '',
        category: '',
        mfgDate: '',
        expDate: '',
        image: null,
        purchasePrice: '',
        originalPrice: '',
        sellPrice: '',
        sellPriceTax: '',
        tax: '',
        discountType: '',
        discountGiven: '',
        description: '',
        prescription: ''
    });


    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://api.kcmpharmacy.com/dist/get-product');
                if (response.ok) {
                    const data = await response.json();
                    const formattedData = data.map(item => ({
                        ...item,
                        mfg: item.mfg ? item.mfg.split(' ')[0] : '',
                        exp: item.exp ? item.exp.split(' ')[0] : '' 
                    }));
                    setItem(formattedData);
                } else {
                    console.error('Failed to fetch users');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchProducts();
    }, []);


    const showDeleteItem = (items) => {
        setSelectedItem(items);
        setShowDelete(true);
    }


    const handleDeleteItem = async (productId) => {
        try {
            const response = await fetch(`https://api.kcmpharmacy.com/dist/delete-product/${productId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setItem((prevItem) => prevItem.filter((product) => product.id !== productId));
                setResponse('Product deleted successfully!');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
                setShowDelete(false);
            } else {
                setResponse('Unable to Delete product.');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };


    const showEditItem = (items) => {
        setSelectedItem(items);
        console.log(items);
        setShowEdit(true);
        setFormData({
            productName: items.name || '',
            productUnit: items.unit || '',
            barcode: items.barcode || '',
            hsnCode: items.hsn_code || '',
            category: items.category || '',
            mfgDate: items.mfg || '',
            expDate: items.exp || '',
            image: items.image != null ? `https://api.kcmpharmacy.com/uploads/${items.image}` : Camera,
            purchasePrice: items.purchase_price || '',
            originalPrice: items.org_price || '',
            sellPrice: items.sell_price || '',
            sellPriceTax: items.tax_type || 'with tax',
            tax: items.tax_amount || 'none',
            discountType: items.discount_type || '',
            discountGiven: items.discount_amount || '0',
            stock: items.stock,
            description: items.description || '',
            prescription: items.prescription || ''
        });
    }


    const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [id]: files[0]
            });
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };


    const handleEditItem = async (e) => {
        e.preventDefault();
        try {
          const formDataToSend = new FormData();
          Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
          });
    
          const response = await fetch(`https://api.kcmpharmacy.com/dist/update-product/${selectedItem.id}`, {
            method: 'PUT',
            body: formDataToSend
          });
    
          if (response.ok) {
            const updatedProduct = await response.json();
            // setProducts((prevProducts) =>
            //   prevProducts.map((product) =>
            //     product.id === selectedProduct.id ? updatedProduct : product
            //   )
            // );
            setResponse('Product updated successfully!');
            setTimeout(() => {
              setResponse('');
              setShowEdit(false);
              window.location.reload();
            }, 1000);
          } else {
            setResponse('Unable to update product.');
            setTimeout(() => {
              setResponse('');
            }, 2000);
          }
        } catch (error) {
          console.error('Error updating product:', error);
        }
      };
    

    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>Inventory</p>
                    <hr className='profile-hr mb-0' />

                    <div className='table-responsive'>
                        <table className='w-100'>

                            <tr className='inventory-head'>
                                <th>Product Name</th>
                                <th>MRP (₹)</th>
                                <th>Pur. Price (₹)</th>
                                <th>Sell Price (₹)</th>
                                <th>Discount</th>
                                <th>Tax</th>
                                <th>Edit/ Del</th>
                            </tr>


                            {item.length > 0 ? (
                                item.map((items) => (
                                    <tr className='inventory-data'>
                                        <td>{items.name}</td>
                                        <td>₹ {items.org_price}</td>
                                        <td>₹ {items.sell_price}</td>
                                        <td>₹ {items.purchase_price}</td>
                                        <td>{items.discount_type == 'amount' ? '₹' : ''} {items.discount_amount} {items.discount_type == 'percent' ? '%' : ''}</td>
                                        <td>{items.tax_amount}</td>
                                        <td><span className='cursor-pointer' onClick={() => showEditItem(items)}><i class="fa-solid fs-5 fa-pen-to-square"></i></span>&nbsp;&nbsp;
                                            <span className='cursor-pointer' onClick={() => showDeleteItem(items)}><i class="fa-solid fs-5 fa-circle-minus text-danger"></i></span></td>
                                    </tr>
                                ))
                            ) : (
                                <p>No Products added yet.</p>
                            )}

                        </table>
                    </div>

                </div>
            </section>


            {showDelete && (
                <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowDelete(false)}>
                    <div className='detail-dialog-inner col-lg-5 mx-auto px-lg-5 py-lg-5 px-3 py-3 text-center' onClick={(e) => e.stopPropagation()}>

                        <p className='profile-subheading'>Want to remove {selectedItem.name}?</p>

                        <button className='login-btn' onClick={() => handleDeleteItem(selectedItem.id)}>Yes</button>&nbsp;&nbsp;
                        <button className='login-btn bg-danger' onClick={() => setShowDelete(false)}>No</button>

                    </div>
                </section>
            )}


            {showEdit && (
                <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowEdit(false)}>
                    <div className='detail-dialog-inner col-lg-10 mx-auto px-lg-5 py-lg-4 px-3 py-3' onClick={(e) => e.stopPropagation()}>

                        <form className="row g-3 bg-white" onSubmit={handleEditItem}>
                            {/* Product Name */}

                            <input
                                type="file"
                                className="profile-input bg-white"
                                id="image"
                                ref={fileInputRef}
                                onChange={handleChange}
                                style={{ display: 'none' }}
                            />
                            <img
                                src={imagePreview || formData.image}
                                className='inventory-img w-auto mx-auto'
                                alt="product"
                                onClick={handleImageClick}
                            />

                            <p className='profile-subheading mt-4'>Product Details</p>

                            <div className="col-md-3">
                                <label htmlFor="productName" className="profile-label text-muted">Product Name</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="productName"
                                    value={formData.productName}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Product Unit */}
                            <div className="col-md-3">
                                <label htmlFor="productUnit" className="profile-label text-muted">Product Unit</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="productUnit"
                                    placeholder='Tablets, Capsules, Gel, Supplements...'
                                    value={formData.productUnit}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Category */}
                            <div className="col-md-3">
                                <label htmlFor="category" className="profile-label text-muted">Category</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="category"
                                    placeholder='Fever, Cold, Headache ...'
                                    value={formData.category}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Stock */}
                            <div className="col-md-3">
                                <label htmlFor="stock" className="profile-label text-muted">Stock</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="stock"
                                    value={formData.stock}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Barcode */}
                            <div className="col-md-3">
                                <label htmlFor="barcode" className="profile-label text-muted">Barcode</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="barcode"
                                    value={formData.barcode}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* HSN Code */}
                            <div className="col-md-3">
                                <label htmlFor="hsnCode" className="profile-label text-muted">HSN Code</label>
                                <input
                                    type="text"
                                    className="profile-input"
                                    id="hsnCode"
                                    value={formData.hsnCode}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Manufacturing Date */}
                            <div className="col-md-3">
                                <label htmlFor="mfgDate" className="profile-label text-muted">Mfg Date</label>
                                <input
                                    type="date"
                                    className="profile-input"
                                    id="mfgDate"
                                    value={formData.mfgDate}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Expiry Date */}
                            <div className="col-md-3">
                                <label htmlFor="expDate" className="profile-label text-muted">Exp Date</label>
                                <input
                                    type="date"
                                    className="profile-input"
                                    id="expDate"
                                    value={formData.expDate}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Image Upload */}
                            {/* <div className="col-md-4">
                            <label htmlFor="image" className="profile-label text-muted">Upload Image</label>
                            <input
                                type="file"
                                className="profile-input bg-white"
                                id="image"
                                onChange={handleChange}
                            />
                        </div> */}

                            <p className='profile-subheading mt-5'>Price Details</p>

                            {/* Purchase Price */}
                            <div className="col-md-4">
                                <label htmlFor="purchasePrice" className="profile-label text-muted">Purchase Price</label>
                                <input
                                    type="number"
                                    className="profile-input"
                                    id="purchasePrice"
                                    value={formData.purchasePrice}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Original Price */}
                            <div className="col-md-4">
                                <label htmlFor="originalPrice" className="profile-label text-muted">Original MRP</label>
                                <input
                                    type="number"
                                    className="profile-input"
                                    id="originalPrice"
                                    value={formData.originalPrice}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Sell Price */}
                            <div className="col-md-4">
                                <label htmlFor="sellPrice" className="profile-label text-muted">Sell Price</label>
                                <input
                                    type="number"
                                    className="profile-input"
                                    id="sellPrice"
                                    value={formData.sellPrice}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Sell Price Tax */}
                            <div className="col-md-3">
                                <label className="profile-label text-muted" htmlFor="sellPriceTax">Sell Price Tax</label>
                                <select
                                    className="profile-input"
                                    id="sellPriceTax"
                                    value={formData.sellPriceTax}
                                    onChange={handleChange}
                                >
                                    <option value="with tax" selected>With Tax</option>
                                    <option value="without tax">Without Tax</option>
                                </select>
                            </div>

                            {/* Tax Applicable */}
                            <div className="col-md-3">
                                <label className="profile-label text-muted" htmlFor="tax">Tax Applicable</label>
                                <select
                                    className="profile-input"
                                    id="tax"
                                    value={formData.tax}
                                    onChange={handleChange}
                                    selected
                                >
                                    <option value="none">None</option>
                                    <option value="GST 0">GST 0%</option>
                                    <option value="IGST 0">IGST 0%</option>
                                    <option value="GST 0.25">GST 0.25%</option>
                                    <option value="IGST 0.25">IGST 0.25%</option>
                                    <option value="GST 3">GST 3%</option>
                                    <option value="IGST 3">IGST 3%</option>
                                    <option value="GST 5">GST 5%</option>
                                    <option value="IGST 5">IGST 5%</option>
                                    <option value="GST 12">GST 12%</option>
                                    <option value="IGST 12">IGST 12%</option>
                                    <option value="GST 18">GST 18%</option>
                                    <option value="IGST 18">IGST 18%</option>
                                    <option value="GST 28">GST 28%</option>
                                    <option value="IGST 28">IGST 28%</option>
                                </select>
                            </div>

                            {/* Discount Type */}
                            <div className="col-md-3">
                                <label className="profile-label text-muted" htmlFor="discountType">Sell Price Discount</label>
                                <select
                                    className="profile-input"
                                    id="discountType"
                                    value={formData.discountType}
                                    onChange={handleChange}
                                    selected
                                >
                                    <option value="">Choose...</option>
                                    <option value="percent">Percent %</option>
                                    <option value="amount">Amount ₹</option>
                                </select>
                            </div>

                            {/* Discount Given */}
                            <div className="col-md-3">
                                <label className="profile-label text-muted" htmlFor="discountGiven">Discount Given</label>
                                <input
                                    type="number"
                                    className="profile-input"
                                    id="discountGiven"
                                    value={formData.discountGiven}
                                    onChange={handleChange}
                                />
                            </div>


                            <p className='profile-subheading mt-5'>Related Info</p>

                            {/* Description */}
                            <div className="col-md-4">
                                <label htmlFor="description" className="profile-label text-muted">Description</label>
                                <textarea
                                    className="profile-input"
                                    style={{ height: '9em' }}
                                    id="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Prescription */}
                            <div className="col-md-4">
                                <label htmlFor="prescription" className="profile-label text-muted">Prescription</label>
                                <textarea
                                    className="profile-input"
                                    style={{ height: '9em' }}
                                    id="prescription"
                                    value={formData.prescription}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Submit Button */}
                            <div className="col-12 text-end">
                            <button type="submit" className="login-btn bg-danger" onClick={() => setShowEdit(false)}>Close</button>&nbsp;&nbsp;
                            <button type="submit" className="login-btn">Update</button>
                            </div>
                        </form>

                    </div>
                </section>
            )}

            {response && <ResponseMsg message={response} />}

        </>
    )
}

export default Inventory

import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// admin imports
import AdminDashboard from './Admin/AdminDashboard';
import Sidebar from './general/Sidebar';
import Profile from './Admin/Profile';
import AddParty from './Admin/AddParty';
import ViewParty from './Admin/ViewParty';
import AddProduct from './Admin/AddProduct';
import Inventory from './Admin/Inventory';
import AddOffer from './Admin/AddOffer';
import ManageDues from './Admin/ManageDues';
import PaymentHistory from './Admin/PaymentHistory';
// page imports
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import OrderHistory from './components/OrderHistory';
import ProductDetails from './components/ProductDetails';
// general imports
import PageNotFound from './general/PageNotFound';
import ScrollTop from './general/ScrollTop';
import ManageOrders from './Admin/ManageOrders';

const App = () => {
  const collapsed = false;
  const userData = JSON.parse(localStorage.getItem('userData'));
  const isAdmin = localStorage.getItem(('isAdmin'));
  const isUser = localStorage.getItem(('isUser'));

  return (
    <>
      <BrowserRouter>
        <ScrollTop />

        {!isUser && !isAdmin && (
          <>
            <Routes>
              <Route exact path='/' element={<Login />} />
              <Route exact path='*' element={<PageNotFound />} />
            </Routes>
          </>
        )}

        {isAdmin && (
          <>
          <Sidebar isAdmin={isAdmin} />
                      <div className={`dashboard-sidebar-right ${collapsed ? "collapsed" : ""}`}>
            <Routes>
              <Route exact path='/' element={<AdminDashboard userData={userData[0]} />} />
              <Route exact path='/admin-dashboard' element={<AdminDashboard userData={userData[0]} />} />
              <Route exact path='/manage-profile' element={<Profile userData={userData[0]} />} />
              <Route exact path='/add-party' element={<AddParty />} />
              <Route exact path='/view-party' element={<ViewParty userData={userData[0]} />} />
              <Route exact path='/add-product' element={<AddProduct />} />
              <Route exact path='/inventory' element={<Inventory />} />
              <Route exact path='/manage-orders' element={<ManageOrders />} />
              <Route exact path='/payment-history' element={<PaymentHistory userData={userData[0]} />} />
              <Route exact path='/manage-dues' element={<ManageDues />} />
              <Route exact path='/add-offers' element={<AddOffer />} />
              <Route exact path='*' element={<PageNotFound />} />
            </Routes>
            </div>
          </>
        )}

        {isUser && (
          <>
          <Sidebar isUser={isUser} />
          <div className={`dashboard-sidebar-right ${collapsed ? "collapsed" : ""}`}>
          <Routes>
              <Route exact path='/' element={<Dashboard />} />
              <Route exact path='/dashboard' element={<Dashboard userData={userData[0]} />} />
              <Route exact path='/manage-profile' element={<Profile userData={userData[0]} />} />
              <Route exact path='/order-history' element={<OrderHistory userData={userData[0]} />} />
              <Route exact path='/product-details/:id' element={<ProductDetails userData={userData[0]} />} />
              <Route exact path='*' element={<PageNotFound />} />
            </Routes>
            </div>
          </>
        )}


      </BrowserRouter>
    </>
  )
}

export default App

import React, { useState } from 'react'
import Logo from '../assets/logo.png';
import '../css/general/Sidebar.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Sidebar = ({ isUser, isAdmin }) => {
    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('isAdmin', 'true');
        localStorage.removeItem('isUser', 'true');
        localStorage.removeItem('cart');
        navigate('/');
        window.location.reload();
    }

    return (
        <>
            <div className={`sidebar-area ${collapsed ? "" : "collapsed"}`}>

                <span className="w-auto sidebar-item py-2 px-3" onClick={toggleSidebar}>
                    <i className={`fa-solid ${collapsed ? "fa-angle-left" : "fa-angle-right"}`}></i>
                </span>

                <div className='text-center'>
                    <img src={Logo} className='sidebar-logo mt-4' alt='Logo Sidebar' />
                </div>

                {isAdmin && (
                    <div className='mt-5'>
                        <p className='sidebar-item' onClick={() => { navigate('/admin-dashboard'); setCollapsed(false) }}><i class="fa-solid fa-chart-pie"></i><Link className='sidebar-text'>Dashboard</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/manage-profile'); setCollapsed(false) }}><i class="fa-solid fa-id-badge"></i><Link className='sidebar-text'>Manage Profile</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/add-party'); setCollapsed(false) }}><i class="fa-solid fa-network-wired"></i><Link className='sidebar-text'>Add Party</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/view-party'); setCollapsed(false) }}><i class="fa-solid fa-eye"></i><Link className='sidebar-text'>View Parties</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/add-product'); setCollapsed(false) }}><i class="fa-solid fa-bag-shopping"></i><Link className='sidebar-text'>Add Products</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/inventory'); setCollapsed(false) }}><i class="fa-solid fa-list"></i><Link className='sidebar-text'>Inventory</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/manage-orders'); setCollapsed(false) }}><i class="fa-solid fa-shopping-cart"></i><Link className='sidebar-text'>Manage Orders</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/payment-history'); setCollapsed(false) }}><i class="fa-solid fa-money-bills"></i><Link className='sidebar-text'>Payment History</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/manage-dues'); setCollapsed(false) }}><i class="fa-solid fa-pen"></i><Link className='sidebar-text'>Manage Dues</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/add-offers'); setCollapsed(false) }}><i class="fa-solid fa-gift"></i><Link className='sidebar-text'>Add Offers</Link></p>
                        <p className='sidebar-item' onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i><Link className='sidebar-text'>Logout</Link></p>
                    </div>
                )}

                {isUser && (
                    <div className='mt-5'>
                        <p className='sidebar-item' onClick={() => { navigate('/dashboard'); setCollapsed(false) }}><i class="fa-solid fa-house-circle-check"></i><Link className='sidebar-text'>Dashboard</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/manage-profile'); setCollapsed(false) }}><i class="fa-solid fa-id-badge"></i><Link className='sidebar-text'>Manage Profile</Link></p>
                        <p className='sidebar-item' onClick={() => { navigate('/order-history'); setCollapsed(false) }}><i class="fa-solid fa-clock-rotate-left"></i><Link className='sidebar-text'>Order History</Link></p>
                        <p className='sidebar-item' onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i><Link className='sidebar-text'>Logout</Link></p>
                    </div>
                )}



                <span className="sidebar-btn py-2" onClick={toggleSidebar}>
                    <i className={`fa-solid ${collapsed ? "fa-angle-left" : "fa-angle-right"}`}></i>
                </span>
            </div>
        </>
    )
}

export default Sidebar

import React, {useState} from 'react'
import ResponseMsg from '../general/ResponseMsg';

const AddParty = () => {
        const [response, setResponse] = useState();
        const [formData, setFormData] = useState({
            name: '',
            mobile: '',
            email: '',
            gst: '',
            gst_type: '',
            gst_state: '',
            gst_address: '',
            password: '',
        });
    
            // Handle form field changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };


    const handleAddParty = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.kcmpharmacy.com/dist/add-party', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setResponse("Party Added Successfully");
                setTimeout(() => {
                    setResponse('');
                    setFormData({
                        name: '',
                        mobile: '',
                        email: '',
                        gst: '',
                        gst_type: '',
                        gst_state: '',
                        gst_address: '',
                        password: '',            
                    });
                }, 2000)
            }
            else {
                setResponse("Unable to add Party");
                setTimeout(() => {
                    setResponse('');
                }, 2000)
            }
        } catch (error) {
            console.log(error);
        }
    };


  return (
    <>
                <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>Add Party</p>
                    <hr className='profile-hr' />

                    <form className="row g-3 mt-4" onSubmit={handleAddParty}>
                                    <div className="col-12">
                                        <label htmlFor="name" className="profile-label">Party Name</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="mobile" className="profile-label">Party Mobile No</label>
                                        <input
                                            type="tel"
                                            className="profile-input"
                                            id="mobile"
                                            maxLength='10'
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="email" className="profile-label">Email</label>
                                        <input
                                            type="email"
                                            className="profile-input"
                                            id="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gst" className="profile-label">Party GSTIN</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst"
                                            value={formData.gst}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gst_type" className="profile-label">GSTIN Type</label>
                                        <select
                                            className="profile-input"
                                            id="gst_type"
                                            value={formData.gst_type}
                                            onChange={handleChange}
                                        >
                                            <option value="Registered">Registered</option>
                                            <option value="Not Registered">Not Registered</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gst_state" className="profile-label">GSTIN State</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst_state"
                                            value={formData.gst_state}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gst_address" className="profile-label">GSTIN Address</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst_address"
                                            value={formData.gst_address}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="password" className="profile-label">Password</label>
                                        <input
                                            type="password"
                                            className="profile-input"
                                            id="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 text-end">
                                        <button type="submit" className="login-btn">Submit Details</button>
                                    </div>
                                </form>


                    </div>
                    </section>

                    {response && <ResponseMsg message={response} />}
    </>
  )
}

export default AddParty

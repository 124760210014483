import React, { useState, useEffect } from 'react'
import '../css/Admin/Profile.css';
import ProfileImg from '../assets/profile.png';
import ResponseMsg from '../general/ResponseMsg';

const Profile = ({ userData }) => {
    const [response, setResponse] = useState();
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        address: '',
        city: '',
        state: '',
        gender: '',
        gst: '',
        gst_type: '',
        gst_state: '',
        gst_address: '',
        password: '',
    });

    // fetch userdetails from backend
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch('https://api.kcmpharmacy.com/dist/get-users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: userData.id, mobile: userData.mobile }),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setFormData({
                    name: data.name || '',
                    mobile: data.mobile || '',
                    email: data.email || '',
                    address: data.address || '',
                    city: data.city || '',
                    state: data.state || '',
                    gender: data.gender || '',
                    gst: data.gst || '',
                    gst_type: data.gst_type || '',
                    gst_state: data.gst_state || '',
                    gst_address: data.gst_address || '',            
                    password: data.password || '',
                });
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchUserDetails();
    }, [userData]);


    // Handle form field changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

        // Handle form submission (update profile)
        const handleSubmit = async (e) => {
            e.preventDefault();
    
            try {
                const response = await fetch(`https://api.kcmpharmacy.com/dist/update-profile/${userData.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });
    
                const result = await response.json();
                if (response.ok) {
                    localStorage.setItem('userData', JSON.stringify(result));
                    console.log(result);
                    setResponse('Profile updated successfully!');
                    setTimeout(() => {
                        setResponse('');
                        window.location.reload();
                    }, 1500);
                } else {
                    setResponse('Failed to update profile.');
                    setTimeout(() => {
                        setResponse('');
                    }, 2000);
                }
            } catch (error) {
                console.error('Error updating profile:', error);
            }
        };

    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3'>
                    <p className='profile-heading'>Distributor's Profile</p>
                    <hr className='profile-hr' />

                    <div className='row mt-lg-5 mt-4'>

                        <div className='col-lg-4 col-md-5 col-12 my-3'>
                            <div className='profile-card'>
                                <div className='profile-img-area'>
                                    <img src={ProfileImg} className='profile-img' alt='Profile' /></div>
                                <div className='text-start pt-4 pb-2 px-4'>
                                    <p className='profile-text'>Distributor Name: {formData.name || 'NA'}</p>
                                    <p className='profile-text'>Mobile: {formData.mobile || 'NA'}</p>
                                    <p className='profile-text'>GSTIN No: {formData.gst || 'NA'}</p>
                                    <p className='profile-text'>Address: {formData.address} {formData.city} {formData.state}</p>
                                </div>

                            </div>
                        </div>
                        {/* left ends */}


                        <div className='col-lg-8 col-md-7 col-12 my-3'>
                            <p className='profile-subheading'>Manage Details</p>
                            <form className="row g-3" onSubmit={handleSubmit}>
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="profile-label">Name</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gender" className="profile-label">Gender</label>
                                        <select
                                            className="profile-input"
                                            id="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="mobile" className="profile-label">Mobile Number</label>
                                        <input
                                            type="tel"
                                            className="profile-input"
                                            id="mobile"
                                            maxLength='10'
                                            value={formData.mobile}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email" className="profile-label">Email</label>
                                        <input
                                            type="email"
                                            className="profile-input"
                                            id="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="address" className="profile-label">Address</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="city" className="profile-label">City</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="state" className="profile-label">State</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="gst" className="profile-label">GSTIN</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst"
                                            value={formData.gst}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="gst_type" className="profile-label">GSTIN Type</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst_type"
                                            value={formData.gst_type}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="gst_state" className="profile-label">GSTIN State</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst_state"
                                            value={formData.gst_state}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="gst_address" className="profile-label">GSTIN Address</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="gst_address"
                                            value={formData.gst_address}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="password" className="profile-label">Password</label>
                                        <input
                                            type="text"
                                            className="profile-input"
                                            id="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-12 text-end">
                                        <button type="submit" className="login-btn">Update Profile</button>
                                    </div>
                                </form>
                        </div>


                    </div>
                    {/* row ends */}

                </div>
            </section>

            {response && <ResponseMsg message={response} />}
        </>
    )
}

export default Profile

import React, { useState, useRef } from 'react'
import ResponseMsg from '../general/ResponseMsg';
import Camera from '../assets/camera-img.png';
import '../css/Admin/AddProduct.css';

const AddProduct = () => {
    const [response, setResponse] = useState(null);
    const [formData, setFormData] = useState({
        productName: '',
        productUnit: '',
        stock: '',
        barcode: '',
        hsnCode: '',
        category: '',
        mfgDate: '',
        expDate: '',
        image: null,
        purchasePrice: '',
        originalPrice: '',
        sellPrice: '',
        sellPriceTax: 'with tax',
        tax: 'none',
        discountType: '',
        discountGiven: '',
        description: '',
        prescription: ''
    });
    const [imagePreview, setImagePreview] = useState(null);


    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click(); // Trigger file input click
    };

    const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [id]: files[0]
            });
            // Update image preview
            const file = files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else {
            setFormData({
                ...formData,
                [id]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSubmit.append(key, formData[key]);
        });

        try {
            const response = await fetch('https://api.kcmpharmacy.com/dist/add-product', {
                method: 'POST',
                body: formDataToSubmit
            });
            if (response.ok) {
                setResponse('Product added successfully!');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
                setFormData({
                    productName: '',
                    productUnit: '',
                    stock: '',
                    barcode: '',
                    hsnCode: '',
                    category: '',
                    mfgDate: '',
                    expDate: '',
                    image: null,
                    purchasePrice: '',
                    originalPrice: '',
                    sellPrice: '',
                    sellPriceTax: 'with tax',
                    tax: 'none',
                    discountType: '',
                    discountGiven: '',
                    description: '',
                    prescription: ''
                });
                setImagePreview(null);
            } else {
                setResponse('Failed to add product.');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>Add Product</p>
                    <hr className='profile-hr' />

                    <form className="row g-3 bg-white rounded-4" onSubmit={handleSubmit}>
                        {/* Product Name */}

                        <input
                            type="file"
                            className="profile-input bg-white"
                            id="image"
                            ref={fileInputRef}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <img
                            src={imagePreview || Camera}
                            className='camera-img w-auto'
                            alt="Logo"
                            onClick={handleImageClick}
                        />

                        <p className='profile-subheading mt-5'>Product Details</p>

                        <div className="col-md-3">
                            <label htmlFor="productName" className="profile-label text-muted">Product Name</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="productName"
                                value={formData.productName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Product Unit */}
                        <div className="col-md-3">
                            <label htmlFor="productUnit" className="profile-label text-muted">Product Unit</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="productUnit"
                                placeholder='Tablets, Capsules, Gel, Supplements...'
                                value={formData.productUnit}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Category */}
                        <div className="col-md-3">
                            <label htmlFor="category" className="profile-label text-muted">Category</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="category"
                                placeholder='Fever, Cold, Headache ...'
                                value={formData.category}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Stock */}
                        <div className="col-md-3">
                            <label htmlFor="stock" className="profile-label text-muted">Stock</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="stock"
                                value={formData.stock}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Barcode */}
                        <div className="col-md-3">
                            <label htmlFor="barcode" className="profile-label text-muted">Barcode</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="barcode"
                                value={formData.barcode}
                                onChange={handleChange}
                            />
                        </div>

                        {/* HSN Code */}
                        <div className="col-md-3">
                            <label htmlFor="hsnCode" className="profile-label text-muted">HSN Code</label>
                            <input
                                type="text"
                                className="profile-input"
                                id="hsnCode"
                                value={formData.hsnCode}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Manufacturing Date */}
                        <div className="col-md-3">
                            <label htmlFor="mfgDate" className="profile-label text-muted">Mfg Date</label>
                            <input
                                type="date"
                                className="profile-input"
                                id="mfgDate"
                                value={formData.mfgDate}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Expiry Date */}
                        <div className="col-md-3">
                            <label htmlFor="expDate" className="profile-label text-muted">Exp Date</label>
                            <input
                                type="date"
                                className="profile-input"
                                id="expDate"
                                value={formData.expDate}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Image Upload */}
                        {/* <div className="col-md-4">
                            <label htmlFor="image" className="profile-label text-muted">Upload Image</label>
                            <input
                                type="file"
                                className="profile-input bg-white"
                                id="image"
                                onChange={handleChange}
                            />
                        </div> */}

                        <p className='profile-subheading mt-5'>Price Details</p>

                        {/* Purchase Price */}
                        <div className="col-md-4">
                            <label htmlFor="purchasePrice" className="profile-label text-muted">Purchase Price</label>
                            <input
                                type="number"
                                className="profile-input"
                                id="purchasePrice"
                                value={formData.purchasePrice}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Original Price */}
                        <div className="col-md-4">
                            <label htmlFor="originalPrice" className="profile-label text-muted">Original MRP</label>
                            <input
                                type="number"
                                className="profile-input"
                                id="originalPrice"
                                value={formData.originalPrice}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Sell Price */}
                        <div className="col-md-4">
                            <label htmlFor="sellPrice" className="profile-label text-muted">Sell Price</label>
                            <input
                                type="number"
                                className="profile-input"
                                id="sellPrice"
                                value={formData.sellPrice}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Sell Price Tax */}
                        <div className="col-md-3">
                            <label className="profile-label text-muted" htmlFor="sellPriceTax">Sell Price Tax</label>
                            <select
                                className="profile-input"
                                id="sellPriceTax"
                                value={formData.sellPriceTax}
                                onChange={handleChange}
                                required
                            >
                                <option value="with tax" selected>With Tax</option>
                                <option value="without tax">Without Tax</option>
                            </select>
                        </div>

                        {/* Tax Applicable */}
                        <div className="col-md-3">
                            <label className="profile-label text-muted" htmlFor="tax">Tax Applicable</label>
                            <select
                                className="profile-input"
                                id="tax"
                                value={formData.tax}
                                onChange={handleChange}
                                selected
                            >
                                <option value="none">None</option>
                                <option value="GST 0">GST 0%</option>
                                <option value="IGST 0">IGST 0%</option>
                                <option value="GST 0.25">GST 0.25%</option>
                                <option value="IGST 0.25">IGST 0.25%</option>
                                <option value="GST 3">GST 3%</option>
                                <option value="IGST 3">IGST 3%</option>
                                <option value="GST 5">GST 5%</option>
                                <option value="IGST 5">IGST 5%</option>
                                <option value="GST 12">GST 12%</option>
                                <option value="IGST 12">IGST 12%</option>
                                <option value="GST 18">GST 18%</option>
                                <option value="IGST 18">IGST 18%</option>
                                <option value="GST 28">GST 28%</option>
                                <option value="IGST 28">IGST 28%</option>
                            </select>
                        </div>

                        {/* Discount Type */}
                        <div className="col-md-3">
                            <label className="profile-label text-muted" htmlFor="discountType">Sell Price Discount</label>
                            <select
                                className="profile-input"
                                id="discountType"
                                value={formData.discountType}
                                onChange={handleChange}
                                selected
                            >
                                <option value="">Choose...</option>
                                <option value="percent">Percent %</option>
                                <option value="amount">Amount ₹</option>
                            </select>
                        </div>

                        {/* Discount Given */}
                        <div className="col-md-3">
                            <label className="profile-label text-muted" htmlFor="discountGiven">Discount Given</label>
                            <input
                                type="number"
                                className="profile-input"
                                id="discountGiven"
                                value={formData.discountGiven}
                                onChange={handleChange}
                            />
                        </div>


                        <p className='profile-subheading mt-5'>Related Info</p>

                        {/* Description */}
                        <div className="col-md-4">
                            <label htmlFor="description" className="profile-label text-muted">Description</label>
                            <textarea
                                className="profile-input"
                                style={{ height: '9em' }}
                                id="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Prescription */}
                        <div className="col-md-4">
                            <label htmlFor="prescription" className="profile-label text-muted">Prescription</label>
                            <textarea
                                className="profile-input"
                                style={{ height: '9em' }}
                                id="prescription"
                                value={formData.prescription}
                                onChange={handleChange}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="col-12 text-end">
                            <button type="submit" className="login-btn">Submit Details</button>
                        </div>
                    </form>

                </div>
            </section>

            {response && <ResponseMsg message={response} />}

        </>
    )
}

export default AddProduct

import React, { useState, useEffect } from 'react'
import BannerBg from '../assets/banner-bg.jpg';
import '../css/components/Dashboard.css';
import ResponseMsg from '../general/ResponseMsg';
import Cart from '../components/Cart.js';
import { useNavigate } from 'react-router-dom';


const Dashboard = ({ userData }) => {
        const [response, setResponse] = useState(null);
    const [item, setItem] = useState([]);
    const [offers, setOffers] = useState([]);
    const [offerImgUrls, setOfferImgUrls] = useState([]);
    const [cart, setCart] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await fetch('https://api.kcmpharmacy.com/dist/get-offers');
                if (response.ok) {
                    const data = await response.json();
                    setOffers(data);
                    const imgUrls = data.map(offer => {
                        return `https://api.kcmpharmacy.com/uploads/${offer.image}`;
                    });
                    setOfferImgUrls(imgUrls);
                } else {
                    console.error('Failed to fetch offers');
                }
            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchOffers();
    }, []);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://api.kcmpharmacy.com/dist/get-product');
                if (response.ok) {
                    const data = await response.json();
                    const formattedData = data.map(item => ({
                        ...item,
                        mfg: item.mfg ? item.mfg.split(' ')[0] : '',
                        exp: item.exp ? item.exp.split(' ')[0] : ''
                    }));
                    console.log(formattedData);
                    setItem(formattedData);
                } else {
                    console.error('Failed to fetch users');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchProducts();
    }, []);


    const handleAddCart = (items) => {
        if(userData.address == ''){
            setResponse('Update Address Details in your profile.');
            setTimeout(() => {
                setResponse('');
            }, 2000);
            return;
          }
          
        if (!items) {
            console.error('Invalid product data');
            setTimeout(() => {
                setResponse('');
            }, 2000);
            return;
        }

        if (items.stock == '0') {
            setResponse('This product is currently out of stock.');
            setTimeout(() => {
                setResponse('');
            }, 2000);
            return;
        }

        const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
        if (!Array.isArray(existingCart)) {
            console.error('Error: existingCart is not an array');
            return;
        }

        const productIndex = existingCart.findIndex(item => item.id === items.id);

        if (productIndex === -1) {
            existingCart.push({ ...items, quantity: 1 });
        } else {
            existingCart[productIndex].quantity += 1;
        }

        localStorage.setItem('cart', JSON.stringify(existingCart));
        setCart(existingCart);
        setResponse('Product added to cart.');
        setTimeout(() => {
            setResponse('');
        }, 2000);
    };

    const filteredItems = item.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    

    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>KCM Medical Store</p>

                    <div className='banner-bg' style={{ backgroundImage: `url(${offerImgUrls[0] || BannerBg})` }}>
                        <div className='container py-5'>

                            {offers.length > 0 ? (
                                offers.map((offer) => (
                                    <>
                                        <p className='fs-5 fw-semibold'>{offer.offer_tagline}</p>
                                        <p className='profile-subheading col-lg-6 col-md-8'>{offer.offer_name}</p>
                                        <p className='col-lg-5 col-md-8 fw-semibold'>{offer.description}</p>
                                    </>
                                ))
                            ) : (
                                <>
                                    <p className='fs-5 fw-semibold'>Get Medicines at your Doorstep</p>
                                    <p className='profile-subheading col-lg-6 col-md-8'>KCM - Your Online Medical Buddy</p>
                                    <p className='col-lg-5 col-md-8 fw-semibold'>We deliver a wide range of medicines directly to your doorstep, ensuring convenience, and reliability. Your health is our top priority!</p>
                                </>)}

                        </div>
                    </div>

                    <div className="text-start my-4">
                        <input
                            type="text"
                            className="profile-input rounded border-0 bg-light"
                            placeholder="🎯 Search products..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <div className='row mt-4'>

                        {filteredItems.length > 0 ? (
                            filteredItems.map((items) => (
                                <div className='col-lg-3 col-md-6 col-6 my-2'>
                                    <div className='product-area'>

                                        <div className='text-center'>
                                            <img src={`https://api.kcmpharmacy.com/uploads/${items.image}`} className='product-img' alt='Product' />
                                        </div>

                                        <p className='product-name my-0 cursor-pointer' onClick={() => navigate(`/product-details/${items.name}`, { state: { items } })}>{items.name}<br />
                                            <span className='product-info'>Pack of {items.unit}</span></p>
                                        <p><span className='product-price'>₹ {items.sell_price}</span> <span className='product-discount'>{items.discount_type == 'amount' ? '₹' : ''}{items.discount_amount != 0 ? `${items.discount_amount}Off` : ''}{items.discount_type == 'percent' ? '%Off' : ''}</span></p>
                                        <button className='add-btn' onClick={() => handleAddCart(items)}>Add Cart <i class="fa-solid fa-cart-shopping"></i></button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Products added yet.</p>
                        )}

                    </div>
                    {/* row ends */}

                </div>
            </section>

            <button className='cart-btn' onClick={() => setShowCart(true)}><i class="fa-solid fa-shopping-cart"></i></button>

            {response && <ResponseMsg message={response} />}

            {showCart && <Cart setShowCart={setShowCart} userData={userData} />}
        </>
    )
}

export default Dashboard

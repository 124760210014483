import React, { useState, useEffect } from 'react'
import '../css/components/Cart.css';
import extraImg from '../assets/empty-cart.gif';
import ResponseMsg from '../general/ResponseMsg';
import SuccessImg from '../assets/order-success.gif';

const Cart = ({ setShowCart, userData }) => {
    const [cart, setCart] = useState([]);
    const [response, setResponse] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const savedCart = JSON.parse(localStorage.getItem('cart'));
        console.log(savedCart);

        if (savedCart && Array.isArray(savedCart)) {
            setCart(savedCart);
        } else {
            setCart([]);
        }
    }, []);

    const handleRemoveItem = (productId) => {
        const updatedCart = cart.filter(item => item.id !== productId);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };


    const handleQuantityChange = (productId, action) => {
        const updatedCart = cart.map(item => {
            if (item.id === productId) {
                const newQuantity = action === 'increment' ? item.quantity + 1 : item.quantity - 1;
                return { ...item, quantity: Math.max(newQuantity, 1) };
            }
            return item;
        });

        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };


    const calculateTotal = () => {
        return cart.reduce((total, product) => total + parseFloat(product.sell_price) * product.quantity, 0).toFixed(2);
    };

    const calculateTotalItem = () => {
        return cart.reduce((total, product) => total + product.quantity, 0);
    };


    const handlePlaceOrder = async () => {
        const orderDetails = cart.map(product => ({
            product_id: product.id,
            product_name: product.name,
            product_unit: product.unit,
            image: product.image,
            product_sp: product.sell_price,
            quantity: product.quantity,
            product_tax: product.tax_amount,
            product_discount_type: product.discount_type,
            product_discount_amount: product.discount_amount
        }));

        // Construct the order object
        const orderData = {
            order_items: orderDetails,
            total_price: calculateTotal(),
            payment_mode: 'Cash',
            payment_id: '',
            payment_status: 'pending',
            user_id: userData.id,
        };

        try {
            const response = await fetch('https://api.kcmpharmacy.com/dist/place-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData),
            });
            const result = await response.json();
            if (response.ok) {
                setShowSuccess(true);
                setTimeout(() => {
                    setCart([]);
                    localStorage.removeItem('cart');
                    setShowSuccess(true);
                }, 2000);
            } else {
                setResponse('Cannot place order. Please try again.');
                setTimeout(() => {
                    setResponse('');
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        };
    };



    return (
        <>
            <section className='cart-area animate__animated animate__fadeInRight'>
                <span className='float-end cursor-pointer' onClick={() => setShowCart(false)}><i class="fa-solid fa-xmark"></i></span>
                <p className='profile-subheading'>Shopping Cart</p>
                <hr className='profile-hr' />


                {cart.length === 0 ? (
                    <div className='text-center my-5'>
                        <p className='fw-semibold'>No Items added in Cart</p>
                        <img src={extraImg} className='img-fluid' style={{ height: '16em' }} alt='empty cart' />
                    </div>
                ) : (
                    <>
                        <div className='cart-item-outer'>
                            {cart.map((product) => (
                                <div className='cart-item mb-3' key={product.id}>
                                    <div className='d-flex justify-content-between align-items-center'>

                                        <div className='d-flex text-start'>
                                            <img src={`https://api.kcmpharmacy.com/uploads/${product.image}`} className='col-xl-3 col-lg-4 col-md-4 col-4' alt={product.name} />

                                            <div className='ms-3 align-content-center'>
                                                <p className='product-name my-0'>{product.name} ({product.unit})</p>
                                                <p className='product-price'>₹ {product.sell_price * product.quantity}</p>
                                                <p>
                                                    <span className='action-btn' onClick={() => handleQuantityChange(product.id, 'decrement')}>-</span>
                                                    <span className='px-2'>{product.quantity}</span>
                                                    <span className='action-btn' onClick={() => handleQuantityChange(product.id, 'increment')}>+</span></p>
                                            </div>
                                        </div>

                                        <span className='text-secondary fs-3 cursor-pointer' onClick={() => handleRemoveItem(product.id)}><i class="fa-solid fa-delete-left"></i></span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className='bg-light px-3 py-3 rounded-3 mt-3'>
                            <p className='fw-bold'>Summary</p>
                            <div className='d-flex mb-2 justify-content-between'><span>Total Items:</span><span>{calculateTotalItem()}</span> </div>
                            <div className='d-flex mb-2 justify-content-between'><span>Order Total:</span><span>{calculateTotal()}</span> </div>
                        </div>

                        <div className='pay-area'>
                            <button className='login-btn' onClick={() => handlePlaceOrder()}>Place Order</button>
                        </div>
                    </>
                )}

            </section>

            {response && <ResponseMsg message={response} />}


{showSuccess && (
    <>
                    <section className='detail-dialog px-3 py-3'>
                    <div className='detail-dialog-inner mx-auto col-lg-8 col-md-10 col-12 py-3 px-3 bg-white text-center animate__animated animate__zoomIn'>
                        <span className='cursor-pointer float-end' onClick={() => setShowSuccess(false)}><i class="fa-solid fa-xmark"></i></span>
                        <img src={SuccessImg} className='img-fluid' />
                        <p className='profile-subheading'>Order Placed Successfully!</p>
                        <p className='text-muted fw-semibold'>Your order will be delivered soon. Thanks for choosing KCM Store.</p>
                    </div>
                </section>
    </>
)}

        </>
    )
}

export default Cart

import React, { useEffect, useState } from 'react'
import ProfileImg from '../assets/profile.png';
import '../css/Admin/ViewParty.css';
import 'animate.css'
import ResponseMsg from '../general/ResponseMsg';

const ViewParty = ({ userData }) => {
  const [party, setParty] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [response, setResponse] = useState();
  const [selectedParty, setSelectedParty] = useState();

  useEffect(() => {
    const fetchParty = async () => {
      try {
        const response = await fetch('https://api.kcmpharmacy.com/dist/get-party');
        if (response.ok) {
          const data = await response.json();
          const filteredParty = data.filter(user => user.id !== userData.id);
          setParty(filteredParty);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchParty();
  }, []);


  const handleShowDetail = (partyData) => {
    setSelectedParty(partyData);
    setShowDetail(true);
  }

  const deleteParty = async () => {
    if (selectedParty) {
      console.log(selectedParty);
      try {
        const response = await fetch(`https://api.kcmpharmacy.com/dist/delete-party/${selectedParty.id}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          setParty((prevParties) => prevParties.filter(party => party.id !== selectedParty.id));
          setResponse("Party removed successfully!");
          setTimeout(() => {
            setResponse('');
            setShowDetail(false);
          }, 2000);
      } else {
          setResponse("Unable to remove party.");
          setTimeout(() => {
            setResponse('');
          }, 2000);
        }
      } catch (error) {
        console.error('Error deleting party:', error);
        alert("Error occurred while removing the party.");
      }
    }
  }

  return (
    <>
      <section className='px-3 py-3'>
        <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3'>
          <p className='profile-heading'>View Party</p>
          <hr className='profile-hr' />

          <div className='row mt-4'>

            {party.length > 0 ? (
              party.map((partyData) => (
                <div className='col-lg-4 col-md-6 col-12 my-2'>
                  <div className='profile-card'>
                    <div className='profile-img-area'>
                      <img src={ProfileImg} className='profile-img' alt='Profile' /></div>
                    <div className='text-start pt-4 pb-2 px-4'>
                      <p className='profile-text'>Party Name: {partyData.name || 'NA'}</p>
                      <p className='profile-text'>Mobile: {partyData.mobile || 'NA'}</p>
                      <p className='profile-text'>Email: {partyData.email || 'NA'}</p>
                      <p className='profile-text'>GSTIN No: {partyData.gst || 'NA'}</p>

                      <div className='text-center'>
                        <button className='login-btn my-2' onClick={() => handleShowDetail(partyData)}><i class="fa-solid fa-list-ul me-2"></i>Details</button></div>

                    </div>

                  </div>
                </div>
              ))
            ) : (
              <p className='fw-bold'>No Party Added yet.</p>
            )}


          </div>
          {/* row ends */}

        </div>
      </section>


      {showDetail && (
        <>
          <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowDetail(false)}>
            <div className='detail-dialog-inner col-lg-8 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>

              <p className='profile-subheading'>Party Details</p>
              <div className='row bg-light px-3 pt-3'>
                <p className='profile-text'>Party Name: {selectedParty.name || 'NA'}</p>

                <div className='col-md-6 px-0'><p className='profile-text'>Mobile: {selectedParty.mobile || 'NA'}</p></div>
                <div className='col-md-6 ps-lg-3 ps-md-3 px-0'><p className='profile-text'>Email: {selectedParty.email || 'NA'}</p></div>

                <div className='col-md-6 px-0'><p className='profile-text'>GSTIN No: {selectedParty.gst || 'NA'}</p></div>
                <div className='col-md-6 ps-lg-3 ps-md-3 px-0'><p className='profile-text'>GSTIN Type: {selectedParty.gst_type || 'NA'}</p></div>

                <div className='col-md-6 px-0'><p className='profile-text'>GSTIN State: {selectedParty.gst_state || 'NA'}</p></div>
                <div className='col-md-6 ps-lg-3 ps-md-3 px-0'><p className='profile-text'>GSTIN Address: {selectedParty.gst_address || 'NA'}</p></div>

              </div>
              {/* row ends */}

              <button className='login-btn mt-4' onClick={() => setShowDetail(false)}>Close</button>&nbsp;&nbsp;
              <button className='login-btn mt-4 bg-danger' onClick={() => deleteParty()}>Remove</button>

            </div>
          </section>
        </>
      )}

{response && <ResponseMsg message={response} />}

    </>
  )
}

export default ViewParty

import React, { useState, useEffect } from 'react'
import '../css/components/OrderHistory.css';

const OrderHistory = ({ userData }) => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedOrderPay, setSelectedOrderPay] = useState();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`https://api.kcmpharmacy.com/dist/get-order/${userData.id}`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          const sortedProducts = data.sort((a, b) => b.id - a.id);
          setOrders(sortedProducts);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchOrders();
  }, [userData.id]);


  const fetchPaymentDetails = async (orderId) => {
    try {
      const response = await fetch(`https://api.kcmpharmacy.com/dist/get-payment/${orderId}`);
      if (response.ok) {
        const data = await response.json();
        setSelectedOrderPay(data);
        console.log('pay', data);
      } else {
        console.error('Failed to fetch payment details');
      }
    } catch (error) {
      console.error('Error fetching payment details:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const filteredOrders = orders.filter(order => {
    const orderItems = JSON.parse(order.order_items);

    const isDateMatch = formatDate(order.order_date).toLowerCase().includes((searchQuery).toLowerCase());
    const isProductNameMatch = orderItems.some(orderItem =>
      orderItem.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return isDateMatch || isProductNameMatch;
  });

  const showInvoiceDialog = (order) => {
    setSelectedOrder(order);
    setShowInvoice(true);
    fetchPaymentDetails(order.id);
  }

  const handlePrint = () => {
    window.print();
  }

  return (
    <>
      <section className='px-3 py-3'>
        <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
          <p className='profile-heading'>Order History</p>
          {/* <hr className='profile-hr my-0' /> */}

          <div className="text-start mb-4">
            <input
              type="text"
              className="profile-input rounded border-0 bg-light"
              placeholder="🎯 Search order here..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>


          {filteredOrders.length > 0 ? (
            filteredOrders.map((order) => {
              const orderItems = JSON.parse(order.order_items);
              return (
                <div className='my-3' key={order.id}>
                  <div className='order-area'>
                    <p className='fw-semibold'>Order placed on {formatDate(order.order_date)}</p>
                    {orderItems.length > 0 ? (
                      orderItems.map((item, index) => (
                        <>
                          <div key={index} className='d-flex mt-2'>
                            <img
                              src={`https://api.kcmpharmacy.com/uploads/${item.image}`}
                              className='col-lg-1 col-md-2 col-sm-2 col-3'
                              alt='Order'
                            />
                            <div className='ms-3 align-content-center'><p className='mb-0 product-name'>{item.product_name}</p>
                              <span className='text-muted para-text'>{item.quantity} Item<br />₹ {item.product_sp * item.quantity}</span></div>
                          </div>
                        </>
                      ))
                    ) : (
                      <p>No items in this order</p>
                    )}
                    <p className='text-muted fw-semibold my-3'>Order Total ₹ {order.total_amount}<br />Your Order has been {order.order_status}</p>
                    {order.order_status === 'Delivered' ? <button className='add-btn rounded' onClick={() => showInvoiceDialog(order)}>Download Invoice <i class="fa-solid fa-file-lines"></i></button> : ''}
                    <hr />
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Orders Placed yet</p>
          )}


        </div>
      </section>


      {showInvoice  && selectedOrder && selectedOrderPay && (
        <>
          <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowInvoice(false)}>
            <div className='invoice-dialog-inner col-lg-8 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>

              <p className='profile-subheading'>Order Invoice</p>

              <div className='invoice-area'>
                <div className='d-flex justify-content-between'>
                  <p>Sold By: KCM Store<br />GSTIN: <br />Invoice No: {selectedOrderPay.id}</p>
                  <p>Order Id: {selectedOrder.id}<br />Order Date: {formatDate(selectedOrder.order_date)}<br />Billing Address: {selectedOrder.user_name}<br />
                    {selectedOrder.user_address} {selectedOrder.user_city} {selectedOrder.user_state}</p>
                </div>
                {/* flex ends */}
                <hr />

                <div className='table-responsive'>
                  <table className='w-100'>
                    <tr>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Tax Value</th>
                      <th>Discount</th>
                      <th>Total ₹</th>
                    </tr>

                    {typeof selectedOrder.order_items === 'string' ? (
                      (() => {
                        try {
                          const parsedItems = JSON.parse(selectedOrder.order_items);
                          if (Array.isArray(parsedItems)) {
                            return parsedItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.product_name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.product_tax}</td>
                                <td>{item.product_discount_type == 'amount' ? '₹' : ''} {item.product_discount_amount} {item.product_discount_type == 'percent' ? '%' : ''}</td>
                                <td>{item.product_sp * item.quantity}</td>
                              </tr>
                            ));
                          }
                        } catch (error) {
                          return <p>Failed to get items.</p>
                        }
                      })()
                    ) : (
                      <p>No items available.</p>
                    )}

                  </table>
                </div>
                {/* table area */}

                <hr />

                <p>Grand Total {selectedOrder.total_amount}<br />Amount Paid: {selectedOrderPay.amount_received} <br />Due Amount: {selectedOrderPay.due_amount}</p>

              </div>
              {/* invoice ends */}
              <button className='login-btn mt-4 bg-danger' onClick={() => setShowInvoice(false)}>Close</button>&nbsp;&nbsp;
              <button className='login-btn mt-4' onClick={handlePrint}>Download</button>


            </div>
          </section>
        </>
      )}

    </>
  )
}

export default OrderHistory

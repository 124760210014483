import React from 'react'

const ResponseMsg = ({message}) => {
  return (
    <>
      <div className='response-area py-2 px-2'>
        <p className='my-0'>{message}</p>
      </div>
    </>
  )
}

export default ResponseMsg

import React, { useState, useEffect } from 'react'

const PaymentHistory = ({ userData }) => {
    const [payData, setPayData] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [showView, setShowView] = useState(false);

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const response = await fetch(`https://api.kcmpharmacy.com/dist/get-payment-history`);
                if (response.ok) {
                    const data = await response.json();
                    const sortedProducts = data.sort((a, b) => b.id - a.id);
                    console.log(data);
                    setPayData(sortedProducts);
                } else {
                    console.error('Failed to fetch products');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchPayments();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    };

    const showViewDialog = (paymentData) => {
        setShowView(true);
        setSelectedItem(paymentData);
    }

    const handlePrint = () => {
        window.print();
    }

    return (
        <>
            <section className='px-3 py-3'>
                <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
                    <p className='profile-heading'>Payment History</p>
                    <hr className='profile-hr my-0' />

                    <div className='table-responsive'>
                        <table className='w-100'>

                            <tr className='inventory-head'>
                                <th>Invoice No</th>
                                <th>Order Date</th>
                                <th>Party Name</th>
                                <th>Order Id</th>
                                <th>Total Amount</th>
                                <th>Amount Paid (₹)</th>
                                <th>View</th>
                            </tr>


                            {payData.length > 0 ? (
                                payData.map((paymentData) => (
                                    <tr className='inventory-data'>
                                        <td>{paymentData.id}</td>
                                        <td>{formatDate(paymentData.order_date)}</td>
                                        <td>{paymentData.customer_name}</td>
                                        <td>{paymentData.order_id}</td>
                                        <td>{paymentData.total_amount}</td>
                                        <td>₹ {paymentData.amount_received}</td>
                                        <td onClick={() => showViewDialog(paymentData)}><span className='cursor-pointer'><i class="fa-solid fs-5 fa-eye"></i></span></td>
                                    </tr>
                                ))
                            ) : (
                                <p>No Products added yet.</p>
                            )}

                        </table>
                    </div>


                </div>
            </section>


            {showView && (
                <>
                    <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowView(false)}>
                        <div className='invoice-dialog-inner col-lg-8 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>

                            <p className='profile-subheading'>Order Invoice</p>

                            <div className='invoice-area'>
                                <div className='d-flex justify-content-between'>
                                    <p>Sold By: KCM Store<br />GSTIN: {userData.gst}<br />Invoice No: {selectedItem.id}</p>
                                    <p>Order Id: {selectedItem.id}<br />Order Date: {formatDate(selectedItem.order_date)}<br />Billing Address: {selectedItem.customer_name}<br />
                                        {selectedItem.customer_address} {selectedItem.customer_city} {selectedItem.customer_state}</p>
                                </div>
                                {/* flex ends */}
                                <hr />

                                <div className='table-responsive'>
                                    <table className='w-100'>
                                        <tr>
                                            <th>Product</th>
                                            <th>Qty</th>
                                            <th>Tax Value</th>
                                            <th>Discount</th>
                                            <th>Total ₹</th>
                                        </tr>

                                        {typeof selectedItem.order_items === 'string' ? (
                                            (() => {
                                                try {
                                                    const parsedItems = JSON.parse(selectedItem.order_items);
                                                    if (Array.isArray(parsedItems)) {
                                                        return parsedItems.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.product_name}</td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.product_tax}</td>
                                                                <td>{item.product_discount_type == 'amount' ? '₹' : ''} {item.product_discount_amount} {item.product_discount_type == 'percent' ? '%' : ''}</td>
                                                                <td>{item.product_sp * item.quantity}</td>
                                                            </tr>
                                                        ));
                                                    }
                                                } catch (error) {
                                                    return <p>Failed to get items.</p>
                                                }
                                            })()
                                        ) : (
                                            <p>No items available.</p>
                                        )}

                                    </table>
                                </div>
                                {/* table area */}

                                <hr />

                                <p>Grand Total {selectedItem.total_amount}<br />Amount Paid: {selectedItem.amount_received} <br />Due Amount: {selectedItem.due_amount}</p>

                            </div>
                            {/* invoice ends */}
                            <button className='login-btn mt-4 bg-danger' onClick={() => setShowView(false)}>Close</button>&nbsp;&nbsp;
                            <button className='login-btn mt-4' onClick={handlePrint}>Download</button>


                        </div>
                    </section>
                    {/* <section className='detail-dialog animate__animated animate__zoomIn' onClick={() => setShowView(false)}>
                        <div className='detail-dialog-inner col-lg-8 col-md-10 col-12 mx-auto px-lg-5 py-lg-5 px-3 py-3' onClick={(e) => e.stopPropagation()}>
                            <p className='profile-subheading my-3'>Order Invoice: {formatDate(selectedItem.order_date)}</p>
                            <p>Party Name: {selectedItem.customer_name}<br />Mobile No: {selectedItem.customer_mobile}</p>


                            <div className='table-responsive'>
                                <table className='w-100'>

                                    <tr>
                                        <th>Product Name</th>
                                        <th>Unit</th>
                                        <th>Quantity</th>
                                        <th>Tax Value</th>
                                        <th>Discount</th>
                                        <th>Total ₹</th>
                                    </tr>
                                    {typeof selectedItem.order_items === 'string' ? (
                                        (() => {
                                            try {
                                                const parsedItems = JSON.parse(selectedItem.order_items);
                                                if (Array.isArray(parsedItems)) {
                                                    return parsedItems.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.product_name}</td>
                                                            <td>{item.product_unit}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.product_tax}</td>
                                <td>{item.product_discount_type == 'amount' ? '₹' : ''} {item.product_discount_amount} {item.product_discount_type == 'percent' ? '%' : ''}</td>
                                                            <td>{item.product_sp}</td>
                                                        </tr>
                                                    ));
                                                }
                                            } catch (error) {
                                                return <p>Failed to get items.</p>
                                            }
                                        })()
                                    ) : (
                                        <p>No items available.</p>
                                    )}

                                </table>
                            </div>

                            <p className='my-3 fw-semibold'>Grand Total: {selectedItem.total_amount}<br />Amount Paid: {selectedItem.amount_received}<br />Due Amount: {selectedItem.due_amount}</p>

                            <button className='login-btn' onClick={() => setShowView(false)}>Close</button>&nbsp;&nbsp;
                        </div>
                    </section> */}
                </>
            )}

        </>
    )
}

export default PaymentHistory

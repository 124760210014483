import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ResponseMsg from '../general/ResponseMsg';
import '../css/components/ProductDetails.css';
import Cart from '../components/Cart.js';

const ProductDetails = ({ userData }) => {
  const [response, setResponse] = useState(null);
  const [cart, setCart] = useState([]);
      const [showCart, setShowCart] = useState(false);
  const location = useLocation();
  const product = location.state?.items;
  console.log(userData);

  if (!product) {
    return <div>No product details available</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  };


  const handleAddCart = (items) => {

    if(userData.address == ''){
      setResponse('Update Address Details in your profile.');
      setTimeout(() => {
          setResponse('');
      }, 2000);
      return;
    }

    if (!items) {
        setResponse('Invalid product data');
        setTimeout(() => {
            setResponse('');
        }, 2000);
        return;
    }

    if (items.stock == '0') {
        setResponse('This product is currently out of stock.');
        setTimeout(() => {
            setResponse('');
        }, 2000);
        return;
    }

    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
    if (!Array.isArray(existingCart)) {
        console.error('Error: existingCart is not an array');
        return;
    }

    const productIndex = existingCart.findIndex(item => item.id === items.id);

    if (productIndex === -1) {
        existingCart.push({ ...items, quantity: 1 });
    } else {
        existingCart[productIndex].quantity += 1;
    }

    localStorage.setItem('cart', JSON.stringify(existingCart));
    setCart(existingCart);
    setResponse('Product added to cart.');
    setTimeout(() => {
        setResponse('');
    }, 2000);
};


  return (
    <>

<section className='px-3 py-3'>
        <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3 animate__animated animate__fadeInUp'>
          <p className='profile-heading'>Product Details</p>
          <hr className='profile-hr' />

          <div className="row">

            <div className="col-lg-4 col-md-5 col-5 col-12 my-2 text-center">
                <img src={`https://api.kcmpharmacy.com/uploads/${product.image}`} alt={product.name} className="product-detail-img" />
            </div>

            <div className="col-lg-6 col-md-7 col-7 col-12 align-content-center my-2">
              <p className='product-name mb-0'>{product.name}</p>
              <span className='fw-bold text-muted'>{product.unit}</span>

              <p className='fs-5 fw-semibold mt-3'>Product Price: <br />
                <span className='fs-4 product-price'>₹ {product.sell_price}{product.discount_amount > 0 && (
                  <span className='text-start ps-2 text-muted fw-semibold fs-6 my-0'>
                    {product.discount_type === 'amount' ? '₹' : ''}{product.discount_amount}{product.discount_type === 'percent' ? '%' : ''} Off
                  </span>
                )}</span></p>
              <button className="add-btn py-2 px-3 mb-3" onClick={() => handleAddCart(product)}>Add to Cart <i class="fa-solid header-icon fa-bag-shopping"></i></button>
              <p className='fw-semibold text-muted'>Category: {product.category}</p>
            </div>

          </div>
          {/* row ends */}


          <div className='row'>

            <div className='col-lg-4 col-md-4 my-1 fw-semibold align-content-center'>Description</div>
            <div className='col-lg-8 col-md-8 my-1'><p className='bg-light px-3 py-3 rounded'>
              Manufactured On: {formatDate(product.mfg)}<br />
              Expire On: {formatDate(product.exp)}<br />
              {product.description}</p></div>

            <div className='col-lg-4 col-md-4 my-1 fw-semibold align-content-center'>Prescription</div>
            <div className='col-lg-8 col-md-8 my-1'><p className='bg-light px-3 py-3 rounded'>{product.description || 'NA'}</p></div>


          </div>
          {/* row ends */}


        </div>
      </section>

      <button className='cart-btn' onClick={() => setShowCart(true)}><i class="fa-solid fa-shopping-cart"></i></button>

      {response && <ResponseMsg message={response} />}
      {showCart && <Cart setShowCart={setShowCart} userData={userData} />}

    </>
  );
};

export default ProductDetails;

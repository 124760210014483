import React, { useState, useEffect } from 'react'
import BannerBg from '../assets/banner-bg.jpg';
import '../css/Admin/AdminDashboard.css';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = ({ userData }) => {
  const [offers, setOffers] = useState([]);
  const [offerImgUrls, setOfferImgUrls] = useState([]);
  const [item, setItem] = useState([]);
  const [party, setParty] = useState([]);
  const [payData, setPayData] = useState([]);
  const [dueData, setDueData] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await fetch('https://api.kcmpharmacy.com/dist/get-offers');
        if (response.ok) {
          const data = await response.json();
          setOffers(data);
          const imgUrls = data.map(offer => {
            return `https://api.kcmpharmacy.com/uploads/${offer.image}`;
          });
          setOfferImgUrls(imgUrls);
        } else {
          console.error('Failed to fetch offers');
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    fetchOffers();
  }, []);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.kcmpharmacy.com/dist/get-product');
        if (response.ok) {
          const data = await response.json();
          setItem(data);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchParty = async () => {
      try {
        const response = await fetch('https://api.kcmpharmacy.com/dist/get-party');
        if (response.ok) {
          const data = await response.json();
          const filteredParty = data.filter(user => user.id !== userData.id);
          setParty(filteredParty);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchProducts();
    fetchParty();
  }, []);


  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`https://api.kcmpharmacy.com/dist/get-payment-history`);
        if (response.ok) {
          const data = await response.json();
          const sortedProducts = data.sort((a, b) => b.id - a.id);
          setPayData(sortedProducts);
          const filteredPayments = sortedProducts.filter(payment => payment.total_amount != payment.amount_received);
          setDueData(filteredPayments);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchPayments();
  }, []);


  const calculateTotalSales = () => {
    return payData.reduce((acc, payment) => {
      const amount = parseFloat(payment.total_amount);
      if (!isNaN(amount)) {
        return acc + amount;
      } else {
        console.error('Invalid total_amount:', payment.total_amount);
        return acc;
      }
    }, 0);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  };


  return (
    <>
      <section className='px-3 py-3'>
        <div className='profile-area-inner px-lg-5 py-lg-5 px-3 py-3'>
          <p className='profile-heading'>Dashboard</p>
          <hr className='profile-hr' />


          <div className='row'>


            <div className='col-lg-9 col-12 my-2'>

              <div className='banner-bg' style={{ backgroundImage: `url(${offerImgUrls[0] || BannerBg})` }}>
                <div className='container py-5'>

                  {offers.length > 0 ? (
                    offers.map((offer) => (
                      <>
                        <p className='fs-5 fw-semibold'>{offer.offer_tagline}</p>
                        <p className='profile-subheading col-lg-6 col-md-8'>{offer.offer_name}</p>
                        <p className='col-lg-5 col-md-8 fw-semibold'>{offer.description}</p>
                      </>
                    ))
                  ) : (
                    <>
                      <p className='fs-5 fw-semibold'>Get Medicines at your Doorstep</p>
                      <p className='profile-subheading col-lg-6 col-md-8'>KCM - Your Online Medical Buddy</p>
                      <p className='col-lg-5 col-md-8 fw-semibold'>We deliver a wide range of medicines directly to your doorstep, ensuring convenience, and reliability. Your health is our top priority!</p>
                    </>)}


                </div>
              </div>
              {/* offer ends */}

            </div>
            {/* left ends */}

            <div className='col-lg-3 col-md-4 col-sm-6 col-12 my-2'>
              <div className='admin-card'>
                <i class="fa-solid fa-chart-simple admin-icon"></i>
                <p className='admin-heading mb-0 mt-3'>Total Sales</p>
                <p className='admin-text'>{calculateTotalSales() || '0'}</p>
                <button className='admin-btn' onClick={() => navigate('/payment-history')}>View Sales</button>
              </div>
            </div>


            <div className='col-lg-3 col-md-4 col-sm-6 col-12 my-2'>
              <div className='admin-card'>
                <i class="fa-solid fa-cubes-stacked admin-icon animate__animated animate__fadeInDown animate__infinite"></i>
                <p className='admin-heading mb-0 mt-3'>Total Products</p>
                <p className='admin-text'>{item.length || '0'}</p>
                <button className='admin-btn' onClick={() => navigate('/inventory')}>Inventory Items</button>
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 col-12 my-2'>
              <div className='admin-card'>
                <i class="fa-solid fa-network-wired admin-icon animate__animated animate__flipInY animate__infinite"></i>
                <p className='admin-heading mb-0 mt-3'>Parties Added</p>
                <p className='admin-text'>{party.length || '0'}</p>
                <button className='admin-btn' onClick={() => navigate('/view-party')}>View Parties</button>
              </div>
            </div>

            <div className='col-lg-6 col-12 my-2'>
              <div className='profile-area-inner px-3 py-3'>

                <div className='d-flex justify-content-between'>
                  <p className='profile-subheading my-2'>Recent Dues</p>
                  <button className='admin-btn' onClick={() => navigate('/manage-dues')}>All Dues</button>
                </div>
                {/* flex ends */}
                <hr className='profile-hr' />

                <div className='table-responsive'>
                  <table className='w-100'>

                    <tr className='inventory-head'>
                      <th>Order Date</th>
                      <th>Party Name</th>
                      <th>Total Amount</th>
                      <th>Due Amount (₹)</th>
                    </tr>


                    {payData.length > 0 ? (
                      payData.slice(0, 3).map((paymentData) => (
                        <tr className='inventory-data'>
                          <td>{formatDate(paymentData.order_date)}</td>
                          <td>{paymentData.customer_name}</td>
                          <td>{paymentData.total_amount}</td>
                          <td className='text-danger'>₹ {paymentData.due_amount}</td>
                        </tr>
                      ))
                    ) : (
                      <p>No Products added yet.</p>
                    )}

                  </table>
                </div>

              </div>
            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
    </>
  )
}

export default AdminDashboard
